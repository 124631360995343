<template>
  <div class="form-group">
    <label :for="input.id" class="font-weight-medium">
      {{ input.label }}
    </label>
    <div
      class="d-flex justify-content-between align-items-center pb-2"
      v-if="options.length > 2"
    >
      <button
        class="btn btn-link p-0"
        @click="selectAll(true)"
        :disabled="model.length == options.length"
      >
        Select all
      </button>
      <button
        class="btn btn-link p-0"
        @click="selectAll(false)"
        :disabled="model.length < 1"
      >
        Unselect all
      </button>
    </div>
    <div class="row mx-0">
      <div
        :class="`col-${input.col || '12'}`"
        class="custom-control custom-checkbox user-select-none"
        v-for="option in options"
        :key="option._id"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          :id="option._id"
          :value="option._id"
          v-model="model"
        />
        <label class="custom-control-label" :for="option._id">
          {{ option.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox-input-component",
  props: ["input", "options"],
  data() {
    return {
      model: [],
    };
  },
  watch: {
    "input.model": {
      handler() {
        this.model = this.input.model;
      },
      deep: true,
      immediate: true,
    },
    model() {
      this.$emit("change", { id: this.input.id, model: this.model });
    },
  },
  methods: {
    selectAll(isTrue) {
      if (isTrue) {
        this.model = this.options.map((option) => option._id);
      } else {
        this.model = [];
      }
    },
  },
};
</script>