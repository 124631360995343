<template>
  <div id="imageViewer">
    <modal
      id="imageViewerModal"
      title="Image Viewer"
      v-if="show"
      :isFull="true"
      @close="close"
    >
      <div
        class="d-flex h-100 w-100 justify-content-center align-items-center"
        style="z-index: 2"
        v-if="isLoading"
      >
        <div class="spinner-border text-primary mr-3"></div>
        <span class="text-muted">Loading preview...</span>
      </div>
      <div
        id="osd"
        class="w-100 h-100"
        :class="{ invisible: isLoading }"
        style="z-index: 1"
      ></div>
    </modal>
  </div>
</template>

<script>
import OSD from "openseadragon";
import Modal from "@/components/Modal";

export default {
  name: "image-viewer-component",
  props: ["show"],
  components: {
    Modal,
  },
  data() {
    return {
      isLoading: false,
      osd: null,
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.isLoading = true;

          this.$nextTick(() => {
            this.osd = OSD({
              id: "osd",
              prefixUrl: "/osd/",
              crossOriginPolicy: "Anonymous",
              maxZoomPixelRatio: 10,
            }).open({
              type: "image",
              url: `${this.show}`,
            });

            this.osd.addHandler("open", () => {
              this.isLoading = false;
            });
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close(e) {
      if (this.osd) {
        this.osd.destroy();

        this.osd = null;
      }

      this.$emit("close", e);
    },
  },
};
</script>