<template>
  <div
    :ref="`mappin${index}`"
    :id="`mappin${index}`"
    class="pin invisible"
    @click="viewSite(site._id)"
    @mouseover="movePin(index, true)"
    @mouseleave="movePin(index, false)"
  >
    <div class="pin-container">
      <div class="icon-container">
        <img src="@/assets/images/tower.png" alt="" />
      </div>
      <div class="pin-title" :ref="`pintitle${index}`">
        <p class="mb-0 w-100 text-truncate small text-muted">
          {{ site.site_id }}
        </p>
        <p class="mb-0 w-100 text-truncate">
          {{ site.name.toLowerCase().toTitleCase() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";

export default {
  name: "site-pin-component",
  props: ["site", "map", "index", "total", "hide"],
  watch: {
    hide: {
      handler() {
        if (this.$el) {
          this.toggleVisibility();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleVisibility() {
      if (this.hide) {
        this.$el.classList.add("d-none");
      } else {
        this.$el.classList.remove("d-none");
      }
    },
    viewSite(id) {
      this.$router.push({
        name: "site",
        params: {
          id: id,
        },
      });
    },
    movePin(index, type) {
      let pin = this.$refs[`mappin${index}`];

      let pinTitle = this.$refs[`pintitle${index}`];

      if (pin && pinTitle) {
        if (type) {
          pin.style.zIndex = this.total * 2 + index;

          pinTitle.style.maxWidth = "fit-content";
        } else {
          pin.style.zIndex = this.total + index;

          pinTitle.style.maxWidth = "180px";
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      new mapboxgl.Marker(this.$el)
        .setLngLat([this.site.lng, this.site.lat])
        .addTo(this.map);

      this.$el.classList.remove("invisible");

      this.toggleVisibility();
    }, 500);
  },
};
</script>