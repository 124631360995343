<template>
  <div id="view-sites" class="pt-4 pt-md-5 pb-3 px-0 px-sm-3">
    <div class="row mx-0 layout-with-sidebar">
      <div
        class="col-12 col-md-6 order-1 pb-3 px-3 d-flex justify-content-start align-items-center"
      >
        <p class="text-muted mb-0 font-weight-bold mr-3 px-3 px-sm-0">
          <i class="fas fa-broadcast-tower mr-2"></i>
          SITES
        </p>
        <button
          class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium d-none d-md-block ml-3"
          @click="addNewSite(true)"
        >
          + Add new site
        </button>
      </div>
      <div class="col-12 col-md-6 order-3 order-md-2 pb-3 px-3">
        <div
          class="d-flex justify-content-between justify-content-md-end align-items-center px-3 px-sm-0"
        >
          <button
            class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium d-md-none d-block"
            @click="addNewSite(true)"
          >
            + Add new site
          </button>
          <div
            class="d-flex justify-content-center align-items-center user-select-none bg-white py-1 rounded-pill shadow-sm cursor-pointer"
          >
            <label
              class="mb-0 mr-2 pl-4 text-muted cursor-pointer"
              :class="{ 'font-weight-bold': !showMap }"
              for="sitesViewSelector"
            >
              Table
            </label>
            <div class="custom-control custom-switch cursor-pointer">
              <input
                type="checkbox"
                class="custom-control-input"
                id="sitesViewSelector"
                v-model="showMap"
              />
              <label
                class="custom-control-label pr-4 text-muted cursor-pointer"
                :class="{ 'font-weight-bold': showMap }"
                for="sitesViewSelector"
              >
                Map
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 order-2 order-md-3 sidebar">
        <div class="px-0 pl-sm-3 pr-sm-3 pr-md-0">
          <div class="custom-card">
            <div class="custom-card-header">
              <div>
                <i class="fas fa-filter mr-2"></i>
                Filters
              </div>
              <button class="btn btn-link p-0" @click="resetFilters()">
                Reset
              </button>
            </div>
            <div class="px-4 p-3">
              <template v-for="filter in filters">
                <component
                  :is="`${filter.type}-input`"
                  :key="filter.id"
                  :input="filter"
                  :options="options && options[filter.id]"
                  @change="filterChanged"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 order-4 content">
        <div class="px-0 pl-sm-3 pr-sm-3 pl-md-0">
          <div class="dashboard-map" v-if="showMap">
            <div class="p-4 shadow-sm bg-light h-100 rounded">
              <div id="map" ref="map"></div>
              <site-pin
                v-for="(site, index) in sitePins"
                :key="site._id"
                :site="site"
                :map="map"
                :index="index"
                :total="sitePins.length"
                :hide="
                  filteredSites.find((fs) => fs._id == site._id) ? false : true
                "
              />
            </div>
          </div>
          <div class="dashboard-table" v-else>
            <div class="table-responsive bg-light p-4 rounded shadow-sm">
              <table class="table table-sm table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th colspan="2">Site ID / Name</th>
                    <th>Address</th>
                    <th>State</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Structure Type</th>
                    <th>PM-Ship</th>
                    <th>Sharer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="site in filteredSites"
                    :key="site._id"
                    class="cursor-pointer"
                    @click="viewSite(site._id)"
                  >
                    <td class="fit-cell text-nowrap">
                      <button
                        class="btn btn-link text-decoration-none px-0"
                        @click.stop="editSite(site)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                    </td>
                    <td class="fit-cell">
                      <div
                        class="cell-image"
                        @click.stop="
                          showModal.imageViewer = Helper.formatMediaUrl(
                            site.header_image
                          )
                        "
                      >
                        <img
                          class="cursor-pointer"
                          :src="Helper.formatMediaUrl(site.header_image)"
                          v-if="site.header_image"
                        />
                      </div>
                    </td>
                    <td class="large-cell">
                      <p class="mb-0 text-muted">{{ site.site_id }}</p>
                      <p class="mb-0">{{ site.name }}</p>
                    </td>
                    <td class="large-cell">
                      {{ Helper.formatAddress(site.address, site.city, null) }}
                    </td>
                    <td>{{ site.state ? site.state.name : null }}</td>
                    <td class="fit-cell text-nowrap">
                      {{ Helper.formatCoordinate(site.lat) }}
                    </td>
                    <td class="fit-cell text-nowrap">
                      {{ Helper.formatCoordinate(site.lng) }}
                    </td>
                    <td class="fit-cell text-nowrap">
                      {{ site.structuretype ? site.structuretype.name : "" }}
                    </td>
                    <td class="text-nowrap">{{ site.pm_ship }}</td>
                    <td>{{ siteSharers(site) }}</td>
                    <td class="fit-cell text-nowrap">
                      <button
                        class="btn btn-link text-danger text-decoration-none px-0"
                        @click.stop="deleteSite(site)"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="filteredSites.length < 1">
                    <td class="text-center text-muted" colspan="10">
                      <i v-if="sites.length < 0">No site yet</i>
                      <i v-else>No site found</i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      id="siteInfoModal"
      v-if="showModal.siteInfo"
      :title="`${currentSite ? 'Edit' : 'Add new'} site`"
      @toggleSpinner="toggleSpinner"
      @close="addNewSite"
    >
      <site-info-form :options="options" :site="currentSite" />
    </modal>
    <image-viewer
      :show="showModal.imageViewer"
      @close="showModal.imageViewer = false"
    />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import Swal from "sweetalert2";
import SitePin from "@/components/SitePin";
import Modal from "@/components/Modal";
import SiteInfoForm from "@/components/forms/SiteInfo";
import ImageViewer from "@/components/ImageViewer";
import TextInput from "@/components/inputs/Text";
import CheckboxInput from "@/components/inputs/Checkbox";

export default {
  name: "sites-view",
  components: {
    SitePin,
    Modal,
    SiteInfoForm,
    ImageViewer,
    TextInput,
    CheckboxInput,
  },
  data() {
    return {
      sites: [],
      sitePins: [],
      showMap: false,
      map: null,
      showModal: {
        siteInfo: false,
        imageViewer: false,
      },
      options: {
        state: [],
        structuretype: [],
        telco: [],
      },
      currentSite: null,
      filters: [
        {
          type: "text",
          label: "Search",
          id: "search",
          placeholder: "Enter site name or id",
          model: null,
        },
        {
          type: "checkbox",
          label: "State",
          id: "state",
          placeholder: "All",
          includeNull: true,
          model: [],
          col: 6,
        },
      ],
    };
  },
  watch: {
    showMap() {
      if (this.showMap) {
        this.$nextTick(() => {
          const mapContainer = this.$refs.map;

          if (mapContainer) {
            this.initMap();
          }
        });
      } else if (!this.showMap && this.map) {
        this.map.remove();

        this.sitePins = [];

        this.map = null;
      }
    },
  },
  computed: {
    siteSharers() {
      return (site) => {
        if (site.sharerinformations && site.sharerinformations.length > 0) {
          let sharers = [];

          site.sharerinformations.forEach((sharer) => {
            const telco = this.options.telco.find((t) => t._id == sharer.telco);

            if (telco) {
              sharers.push(telco.name);
            }
          });

          return sharers.join(", ");
        } else {
          return "";
        }
      };
    },
    filteredSites() {
      let sites = JSON.parse(JSON.stringify(this.sites));

      const searchFilter = this.filters.find((filter) => filter.id == "search");

      if (searchFilter && searchFilter.model) {
        sites = sites.filter(
          (site) =>
            site.name
              .toLowerCase()
              .includes(searchFilter.model.toLowerCase()) ||
            site.site_id
              .toLowerCase()
              .includes(searchFilter.model.toLowerCase())
        );
      }

      const stateFilter = this.filters.find((filter) => filter.id == "state");

      if (stateFilter && stateFilter.model) {
        sites = sites.filter(
          (site) => site.state && stateFilter.model.includes(site.state._id)
        );
      }

      return sites;
    },
  },
  methods: {
    resetFilters() {
      this.filters = this.filters.map((filter) => {
        if (filter.type == "checkbox") {
          filter.model = this.options[filter.id].map((option) => option._id);
        } else {
          filter.model = null;
        }

        return filter;
      });
    },
    filterChanged(result) {
      let filter = this.filters.find((filter) => filter.id == result.id);

      if (filter) {
        filter.model = result.model;
      }
    },
    viewSite(id) {
      this.$router.push({
        name: "site",
        params: {
          id: id,
        },
      });
    },
    deleteSite(site) {
      Swal.fire({
        html: `<p>Confirm delete this site?</p><p class='font-weight-bold'>${site.name}</p>`,
        icon: "question",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-sm btn-danger",
          cancelButton: "btn btn-sm btn-light",
          actions: "d-flex justify-content-between align-items-center",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.toggleSpinner(true);

          await this.API.del(`sites/${site._id}`);

          this.sites = this.sites.filter((s) => s._id != site._id);

          this.toggleSpinner(false);
        }
      });
    },
    toggleSpinner(e) {
      this.$parent.toggleSpinner(e);
    },
    editSite(site) {
      this.currentSite = site;

      this.addNewSite(true);
    },
    addNewSite(result) {
      if (typeof result == "boolean") {
        this.showModal.siteInfo = result;
      } else if (typeof result == "object") {
        this.showModal.siteInfo = result.show;

        if (result.data) {
          if (this.currentSite) {
            const siteIndex = this.sites.findIndex(
              (site) => site._id == result.data._id
            );

            if (siteIndex > -1) {
              this.$set(this.sites, siteIndex, result.data);
            }
          } else {
            this.sites.push(result.data);

            if (result.data.lat && result.data.lng) {
              this.sitePins.push(result.data);

              this.setMapBounds();
            }
          }
        }

        this.currentSite = null;
      }
    },
    setMapBounds() {
      this.$nextTick(() => {
        if (this.sitePins.length > 0 && this.sitePins.length <= 1) {
          this.map.setCenter([this.sitePins[0].lng, this.sitePins[0].lat]);
        } else if (this.sitePins.length > 1) {
          let bounds = new mapboxgl.LngLatBounds();

          this.sitePins.forEach(function (c) {
            bounds.extend([c.lng, c.lat]);
          });

          this.map.fitBounds(bounds, {
            padding: 50,
            duration: 0,
          });
        }
      });
    },
    initMap() {
      return new Promise((resolve) => {
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;

        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/dark-v9",
          center: [101.5183, 3.0738],
          zoom: 9,
        });

        this.sitePins = this.sites.filter((site) => site.lat && site.lng);

        this.setMapBounds();

        this.map.on("load", () => {
          resolve();
        });
      });
    },
    async getSites() {
      this.toggleSpinner(true);

      this.API.get("sites?_limit=-1")
        .then((retVal) => {
          this.sites = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch((err) => {
          this.toggleSpinner(false);

          return;
        });
    },
    async getStates() {
      this.toggleSpinner(true);

      this.API.get("states?_sort=name:ASC")
        .then((retVal) => {
          this.options.state = retVal.data;

          let stateFilter = this.filters.find((f) => f.id == "state");

          stateFilter.model = this.options.state.map((s) => s._id);

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
    async getStructureType() {
      this.toggleSpinner(true);

      this.API.get("structuretypes?_sort=name:ASC")
        .then((retVal) => {
          this.options.structuretype = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
    async getTelco() {
      this.toggleSpinner(true);

      this.API.get("telcos?_sort=name:ASC")
        .then((retVal) => {
          this.options.telco = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
  },
  async mounted() {
    await this.getStates();
    await this.getStructureType();
    await this.getTelco();
    await this.getSites();
  },
};
</script>